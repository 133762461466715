import store from 'store'
import queryString from 'query-string'

// eslint-disable-next-line import/no-cycle
import { updateToken } from '../jwt'

function RestClient(apiURL, fetchFunc, useToken) {
  const self = this

  self.apiURL = apiURL

  self.fetch = fetchFunc

  const applyTokenForOptions = (options) => {
    if (useToken === true) {
      const token = store.get('app.user.token')

      if (token != null) {
        if (options.headers == null) {
          options.headers = {}
        }
        options.headers.authorization = `JWT ${token}`
      }
    }
  }

  const applyTokenForXHR = (xhr) => {
    if (useToken === true) {
      const token = store.get('app.user.token')

      if (token != null) {
        xhr.setRequestHeader('authorization', `JWT ${token}`)
      }
    }
  }

  self.get = (url, params, opts) => {
    let api = self.apiURL + url

    if (params != null) {
      const search = queryString.stringify(params)
      if (search != null) {
        api += `?${search}`
      }
    }

    const options = {
      method: 'GET',
      ...opts,
    }

    applyTokenForOptions(options)

    const ret = self.fetch.call(window, api, options)

    const promise = new Promise((resolve, reject) => {
      ret
        .then(async (response) => {
          const data = await response.json()
          console.log(' data : ', data)
          const code = response.status
          if (code >= 200 && code < 300) {
            resolve({
              origin: response,
              data,
            })
          } else {
            reject(new Error(data.message || data.detail))
          }
        })
        .catch((error) => {
          reject(error)
        })
    })

    return promise
  }

  self.post = (url, params, opts) => {
    const api = self.apiURL + url

    const options = {
      method: 'POST',
      body: JSON.stringify(params),
      ...opts,
    }

    if (options.headers == null) {
      options.headers = {}
    }

    options.headers['Content-Type'] = 'application/json'

    applyTokenForOptions(options)

    const ret = self.fetch.call(window, api, options)

    const promise = new Promise((resolve, reject) => {
      ret
        .then(async (response) => {
          const data = await response.json()
          console.log(' data : ', data)
          const code = response.status
          if (code >= 200 && code < 300) {
            resolve({
              origin: response,
              data,
            })
          } else {
            reject(new Error(data.message || data.detail))
          }
        })
        .catch((error) => {
          reject(error)
        })
    })

    return promise
  }

  self.put = (url, params, opts) => {
    const api = self.apiURL + url

    const options = {
      method: 'PUT',
      body: JSON.stringify(params),
      ...opts,
    }

    if (options.headers == null) {
      options.headers = {}
    }

    options.headers['Content-Type'] = 'application/json'

    applyTokenForOptions(options)

    const ret = self.fetch.call(window, api, options)

    const promise = new Promise((resolve, reject) => {
      ret
        .then(async (response) => {
          const data = await response.json()
          console.log(' data : ', data)
          const code = response.status
          if (code >= 200 && code < 300) {
            resolve({
              origin: response,
              data,
            })
          } else {
            reject(new Error(data.message || data.detail))
          }
        })
        .catch((error) => {
          reject(error)
        })
    })

    return promise
  }

  self.patch = (url, params, opts) => {
    const api = self.apiURL + url

    const options = {
      method: 'PATCH',
      body: JSON.stringify(params),
      ...opts,
    }

    if (options.headers == null) {
      options.headers = {}
    }

    options.headers['Content-Type'] = 'application/json'

    applyTokenForOptions(options)

    const ret = self.fetch.call(window, api, options)

    const promise = new Promise((resolve, reject) => {
      ret
        .then(async (response) => {
          const data = await response.json()
          console.log(' data : ', data)
          const code = response.status
          if (code >= 200 && code < 300) {
            resolve({
              origin: response,
              data,
            })
          } else {
            reject(new Error(data.message || data.detail))
          }
        })
        .catch((error) => {
          reject(error)
        })
    })

    return promise
  }

  self.delete = (url, params, opts) => {
    const api = self.apiURL + url

    const options = {
      method: 'DELETE',
      body: JSON.stringify(params),
      ...opts,
    }

    if (options.headers == null) {
      options.headers = {}
    }

    options.headers['Content-Type'] = 'application/json'

    applyTokenForOptions(options)

    const ret = self.fetch.call(window, api, options)

    const promise = new Promise((resolve, reject) => {
      ret
        .then(async (response) => {
          const data = await response.json()
          console.log(' data : ', data)
          const code = response.status
          if (code >= 200 && code < 300) {
            resolve({
              origin: response,
              data,
            })
          } else {
            reject(new Error(data.message || data.detail))
          }
        })
        .catch((error) => {
          reject(error)
        })
    })

    return promise
  }

  self.xhrGet = (url, params, onProgress, onBeforeSend, opts) => {
    // console.log(onProgress)
    const promise = new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      // xhr.withCredentials = true;
      xhr.addEventListener('progress', (e) => {
        if (onProgress != null) {
          onProgress(e, (e.loaded * 100) / e.total)
        }
      })
      xhr.addEventListener('load', (e) => {
        const { status } = e.target
        if (status >= 200 && status < 400) {
          let json = {}
          const body = xhr.response
          if (
            (xhr.responseType === '' || xhr.responseType === 'text') &&
            xhr.responseText != null &&
            xhr.responseText.charAt(0) === '{'
          ) {
            json = JSON.parse(xhr.responseText)
          }
          resolve({ status: xhr.status, body, json, data: json, event: e, xhr })
        } else if (status === 401) {
          updateToken()
            .then(() => {
              startReq()
            })
            .catch((err) => {
              reject(err)
            })
        } else {
          reject(e.target.response)
        }
      })

      xhr.addEventListener('error', (err) => reject(err))
      xhr.addEventListener('abort', (e) => reject(e))

      let api = self.apiURL + url

      if (params != null) {
        const search = queryString.stringify(params)
        if (search != null) {
          api += `?${search}`
        }
      }

      function startReq() {
        xhr.open('GET', api, true)

        // xhr.setRequestHeader('Content-Type', 'mutipart/form-data');

        applyTokenForXHR(xhr)

        if (opts != null && opts.headers != null) {
          Object.entries(opts.headers).forEach(([key, value]) => {
            xhr.setRequestHeader(key, value)
          })
        }

        if (onBeforeSend != null) {
          onBeforeSend(xhr)
        }

        xhr.send()
      }

      startReq()
    })

    return promise
  }

  self.xhrPost = (url, formData, onProgress, onBeforeSend, opts) => {
    // console.log(onProgress)
    const promise = new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      // xhr.withCredentials = true;
      if (onProgress != null) {
        xhr.upload.addEventListener('progress', (e) => onProgress(e, (e.loaded * 100) / e.total))
      }
      xhr.addEventListener('load', (e) => {
        const { status } = e.target
        if (status >= 200 && status < 400) {
          let json = {}
          const body = xhr.response
          if (
            (xhr.responseType === '' || xhr.responseType === 'text') &&
            xhr.responseText != null &&
            xhr.responseText.charAt(0) === '{'
          ) {
            json = JSON.parse(xhr.responseText)
          }
          resolve({ status: xhr.status, body, json, data: json, event: e, xhr })
        } else if (status === 401) {
          updateToken()
            .then(() => {
              startReq()
            })
            .catch((err) => {
              reject(err)
            })
        } else {
          reject(e.target.response)
        }
      })

      xhr.addEventListener('error', (err) => reject(err))
      xhr.addEventListener('abort', (e) => reject(e))

      const api = self.apiURL + url

      function startReq() {
        xhr.open('POST', api, true)

        // xhr.setRequestHeader('Content-Type', 'mutipart/form-data');

        applyTokenForXHR(xhr)

        if (opts != null && opts.headers != null) {
          Object.entries(opts.headers).forEach(([key, value]) => {
            xhr.setRequestHeader(key, value)
          })
        }

        if (onBeforeSend != null) {
          onBeforeSend(xhr)
        }

        if (formData != null) {
          xhr.send(formData)
        } else {
          xhr.send()
        }
      }

      startReq()
    })

    return promise
  }

  self.xhrPatch = (url, formData, onProgress, onBeforeSend, opts) => {
    // console.log(onProgress)
    const promise = new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      // xhr.withCredentials = true;
      if (onProgress != null) {
        xhr.upload.addEventListener('progress', (e) => onProgress(e, (e.loaded * 100) / e.total))
      }
      xhr.addEventListener('load', (e) => {
        const { status } = e.target
        if (status >= 200 && status < 400) {
          let json = {}
          const body = xhr.response
          if (
            (xhr.responseType === '' || xhr.responseType === 'text') &&
            xhr.responseText != null &&
            xhr.responseText.charAt(0) === '{'
          ) {
            json = JSON.parse(xhr.responseText)
          }
          resolve({ status: xhr.status, body, json, data: json, event: e, xhr })
        } else if (status === 401) {
          updateToken()
            .then(() => {
              startReq()
            })
            .catch((err) => {
              reject(err)
            })
        } else {
          reject(e.target.response)
        }
      })

      xhr.addEventListener('error', (err) => reject(err))
      xhr.addEventListener('abort', (e) => reject(e))

      const api = self.apiURL + url

      function startReq() {
        xhr.open('PATCH', api, true)

        // xhr.setRequestHeader('Content-Type', 'mutipart/form-data');

        applyTokenForXHR(xhr)

        if (opts != null && opts.headers != null) {
          Object.entries(opts.headers).forEach(([key, value]) => {
            xhr.setRequestHeader(key, value)
          })
        }

        if (onBeforeSend != null) {
          onBeforeSend(xhr)
        }

        if (formData != null) {
          xhr.send(formData)
        } else {
          xhr.send()
        }
      }

      startReq()
    })

    return promise
  }
}

export default RestClient
