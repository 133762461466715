// import 'antd/lib/style/index.less' // antd core styles
import './components/kit/vendors/antd/themes/default.less' // default theme antd components
// import './components/kit/vendors/antd/themes/dark.less' // dark theme antd components
import './global.scss' // app & third-party component styles

import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, useNavigate, useLocation } from 'react-router-dom'
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'
import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { ApolloProvider } from '@apollo/client'

import appolloClient from 'myNet'
import conf from 'conf'
import history from 'history'
import { setMainVisible, setLoginVisible, setContactVisible } from 'popups'

import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Localization from './localization'
import Router from './router'

import * as serviceWorker from './serviceWorker'

const historyOpts = {}
if (process.env.PUBLIC_URL != null) {
  historyOpts.basename = process.env.PUBLIC_URL
}

// middlewared
const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]
// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger)
// }
if (conf.DEBUG === true) {
  middlewares.push(logger)
}

console.info(`Git branch : ${conf.gitInfo.branch}`)
console.info(`Git date : ${conf.gitInfo.commit.date}`)

const store = configureStore({
  reducer: reducers(),
  middleware: middlewares,
});

sagaMiddleware.run(sagas)

if (window.parent === window) {
  window.nPopup = {
    setLoginVisible: (value, step, info) => {
      setMainVisible(false)
      setLoginVisible(value, step, info)
    },
    closeMainPopup: () => {
      setMainVisible(false)
    },
    setContactVisible: (value) => {
      setContactVisible(value)
    },
  }

  window.refreshAccount = () => {
    store.dispatch({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
  }


  window.storageListener = (event) => {
    if (event.storageArea !== localStorage) {
      return
    }
    if (event.key === 'authState') {
      console.log(' --- check --- ', event.newValue, event)
      if (event.newValue != null) {
        const vProp = JSON.parse(event.newValue);
        switch (vProp.state) {
          case 'login':
          case 'redirect':
            window.refreshAccount()
            break;
          case 'logout':
            store.dispatch({
              type: 'user/LOGOUT',
            })
            break;
          default:
        }
      }
    }
  }
  window.addEventListener('storage', window.storageListener)
}

const NavigateSetter = () => {
  history.navigate = useNavigate();
  history.location = useLocation();

  const { location, notifyLocationChange } = history;

  useEffect(() => {
    notifyLocationChange(location);
  }, [location, notifyLocationChange]);

  return null;
};

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <ApolloProvider client={appolloClient}>
    <BrowserRouter>
      <NavigateSetter />
      <Provider store={store}>
        <Localization>
          <Router history={history} />
        </Localization>
      </Provider>
    </BrowserRouter>
  </ApolloProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export { store, history }
