import React from 'react'
import { withRouter } from 'router'

// import Menu from '../Menu/public'

class HeaderDef extends React.Component {
  state = {
    isScrolled: false,
    isNaviShow: false,
    isNaviHide: false,
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0

    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    //  console.log(e)
    const { isScrolled } = this.state
    const minPos = 150
    const changePos = 300
    const top = document.documentElement.scrollTop

    if (top >= changePos && isScrolled === false) {
      this.setState({
        isNaviHide: false,
        isNaviShow: true,
        isScrolled: true,
      })
    } else if (top > minPos && top < changePos && isScrolled === true) {
      this.setState({
        isNaviHide: true,
        isNaviShow: false,
      })
    } else if (top < minPos) {
      this.setState({
        isNaviHide: false,
        isNaviShow: false,
        isScrolled: false,
      })
    }
  }

  onAnimationEnd = () => {
    const changePos = 300
    const top = document.documentElement.scrollTop

    if (top >= changePos) {
      this.setState({
        isScrolled: true,
      })
    } else if (top < changePos) {
      this.setState({
        isScrolled: false,
      })
    }
  }

  render() {
    const { black, menuComp } = this.props
    const { isScrolled, isNaviShow, isNaviHide } = this.state

    const Menu = menuComp
    const navBack = black === true ? 'navbar-black' : 'navbar-white'

    return (
      <div
        className={`navbar ${navBack} ${isScrolled ? 'is-scrolled' : ''} ${
          isNaviHide ? 'slide-down-hide' : ''
        } ${isNaviShow ? 'slide-down' : ''}`}
        onAnimationEnd={this.onAnimationEnd}
      >
        <Menu naviHide={isNaviHide} />
      </div>
    )
  }
}

const Header = withRouter(HeaderDef)

export default Header
