/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unescaped-entities */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withRouter } from 'router'
import { withTranslation } from 'react-i18next'
import { setLoginVisible } from 'popups'

// import Accordion from '../../../components/rework/accordion'

const mapStateToProps = ({ user, dispatch }) => ({
  dispatch,
  user,
})

@connect(mapStateToProps)
class LoginUIDef extends React.Component {
  componentDidMount() {
    const { isPopup } = this.props
    if (isPopup !== true) {
      this.setHeaderBlack(false)
    }
  }

  // componentWillUnmount() {
  //   this.setHeaderBlack(false);
  // }

  setHeaderBlack = value => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/SET_STATE',
      payload: {
        isHeaderBlack: value,
      },
    })
  }

  moveBack = () => {
    const { history } = this.props
    if (history.length === 0) {
      // history.push('/account/users/list')
    } else {
      history.goBack()
    }
  }

  checkEmail = (email, onSuccess) => {
    const url = `https://outlook.office365.com/autodiscover/autodiscover.json/v1.0/${email}?Protocol=ActiveSync`
    window.fetch(url).then(
      res => {
        console.log(res)
        onSuccess('msal')
      },
      err => {
        console.log(err)
        onSuccess('gmail')
      },
    )
  }

  loginWithEmail = e => {
    const { isPopup } = this.props
    if (isPopup === true) {
      setLoginVisible(true, 'loginWithEmail')
      e.preventDefault()
    }
  }

  onOK = () => {
    const { isPopup, history } = this.props
    if (isPopup !== true) {
      history.push('/')
    } else {
      setLoginVisible(false)
    }
  }

  onSubmit = evt => {
    // const { dispatch } = this.props

    console.log('onSubmit - ', evt)
    // const { email, password } = evt.target.elements
    const { email } = evt.target.elements
    const values = {
      email: email.value.trim(),
      // password: password.value.trim(),
    }

    console.log(values)

    // dispatch({
    //   type: 'user/LOGIN',
    //   payload: values,
    // })

    // window.open(`https://dev.api.rework.so/auth/landing/gmail?email=${values.email}`);
    // window.location.href = `https://dev.api.rework.so/auth/landing/gmail?email=${values.email}`

    // window.open('http://localhost:3100/profile?access_token={access_token}', 'Login', 'width=700 height=900');
    // window.open('https://test.rework.so/profile?access_token={access_token}', 'Login', 'width=700 height=900');

    this.checkEmail(values.email, provider => {
      console.log(provider)
      this.onOK()
      window.open(
        `https://dev.api.rework.so/v1/auth/landing/${provider}/desktop?email=${values.email}`,
        'Login',
        'width=700 height=900',
      )
    })

    // if (values.email.indexOf('@9folders.com') > -1) {
    //   window.open(
    //     `https://dev.api.rework.so/v1/auth/landing/msal/desktop?email=${values.email}`,
    //     'Login',
    //     'width=700 height=900',
    //   )
    // } else if (values.email.indexOf('@gmail.com') > -1) {
    //   window.open(
    //     `https://dev.api.rework.so/v1/auth/landing/gmail/desktop?email=${values.email}`,
    //     'Login',
    //     'width=700 height=900',
    //   )
    // } else {
    //   window.open(
    //     `https://dev.api.rework.so/v1/auth/landing/gmail/desktop?email=${values.email}`,
    //     'Login',
    //     'width=700 height=900',
    //   )
    // }

    // const { history } = this.props
    // history.push('/login-frame');

    evt.preventDefault()
  }

  // onFinishFailed = errorInfo => {
  //   console.log('Failed:', errorInfo)
  // }

  render() {
    return (
      <div className="section center">
        <div className="login-form-group">
          <form onSubmit={this.onSubmit}>
            <h1>로그인</h1>
            <div className="form-group">
              <input name="email" type="text" className="form-control" placeholder="Email" />
            </div>
            {/*
            <div className="form-group">
              <input
                name="password"
                type="password"
                className="form-control"
                placeholder="Password"
              />
            </div>
            */}
            <button type="submit" className="btn btn-primary btn-lg btn-block">
              Login
            </button>
            <br />
            <Link className="default-css-a" to="/login-email" onClick={this.loginWithEmail}>
              Login with email
            </Link>
          </form>
        </div>
      </div>
    )
  }
}

const LoginUI = withTranslation()(withRouter(LoginUIDef))

export default LoginUI
