import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'router'
// import classNames from 'classnames'

import Header from 'components/rework/header/public'
import Footer from 'components/rework/footer'

const mapStateToProps = ({ settings }) => ({
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: settings.isGrayBackground,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
  isHeaderBlack: settings.isHeaderBlack,
})

const MainLayout = ({
  children,
  isHeaderBlack,
  // isContentMaxWidth,
  // isAppMaxWidth,
  // isGrayBackground,
  // isSquaredBorders,
  // isCardShadow,
  // isBorderless,
  // isTopbarFixed,
  // isGrayTopbar,
}) => {
  return (
    <div className="main-layout">
      <Header black={isHeaderBlack} />
      <div style={{ height: '100%', position: 'relative' }} className="main-content">
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(MainLayout))
