import React, { Fragment } from 'react'
import { Navigate } from 'react-router-dom'
import { withRouter } from 'router'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import { LoginPath } from 'env'
// import Loader from 'components/cleanui/layout/Loader'
import PublicLayout from './Public'
import AuthLayout from './Auth'
import MainLayout from './Main'
// import AccountLayout from './Account'
import LeftMenuLayout from './LeftMenu'
import EmptyLayout from './Empty'

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
  // account: AccountLayout,
  account: LeftMenuLayout,
  empty: EmptyLayout,
}

const mapStateToProps = ({ user }) => ({ user })
let previousPath = ''

const Layout = ({ user, children, location: { pathname, search } }) => {
  // NProgress & ScrollTop Management
  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0)
    NProgress.start()
  }
  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)

  let isPublic = true

  // Layout Rendering
  const getLayout = () => {
    if (pathname === '/') {
      isPublic = true
      return 'public'
    }
    if (pathname.indexOf('/public') === 0) {
      isPublic = true
      return 'public'
    }
    if (pathname.indexOf('/account') === 0) {
      isPublic = false
      return 'account'
    }
    if (
      pathname.indexOf('/plain') === 0 ||
      pathname.indexOf('/login-frame') === 0 ||
      pathname.indexOf('/profile') === 0 ||
      pathname.indexOf('/redirect') === 0
    ) {
      return 'empty'
    }
    if (/^\/auth(?=\/|$)/i.test(pathname)) {
      isPublic = true
      return 'auth'
    }
    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = user.authorized || isPublic
  const isUserLoading = user.loading
  // const isAuthLayout = getLayout() === 'auth'

  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && isPublic === false) {
      return null
    }
    // redirect to login page if current is not login page and user not authorized
    if (isPublic === false && !isUserAuthorized) {
      return <Navigate to={LoginPath} />
    }
    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      <Helmet titleTemplate="%s" title="Re:Work" />
      {BootstrappedLayout()}
    </Fragment>
  )
}

export default withRouter(connect(mapStateToProps)(Layout))
