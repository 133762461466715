import React from 'react'
import { connect } from 'react-redux'

import Menu from 'components/rework/Menu'

const mapStateToProps = ({ menu }) => ({
  menuData: menu.menuData,
})

const MyMenu = ({ menuData = [] }) => {
  return <Menu menuData={menuData} />
}

export default connect(mapStateToProps)(MyMenu)
