import actions from './actions'

const initialState = {
  menuData: [],
  popups: {}
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_POPUP:
      {
        const newState = { ...state };
        newState.popups = { ...newState.popups, ...action.payload };
        return { ...newState }
      }
    default:
      return state
  }
}
