// eslint-disable-next-line no-unused-vars
import React from 'react'
import { withRouter } from 'router'

// import Header from '../../components/rework/header'
import Footer from '../../components/rework/footer'

const PublicLayout = ({ children }) => {
  return (
    <div className="main-layout">
      {/* <Header /> */}
      <div className="content">{children}</div>
      <Footer />
    </div>
  )
}

export default withRouter(PublicLayout)
