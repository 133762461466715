/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react'
import { withRouter } from 'router'
import { connect } from 'react-redux'

import { setLoginVisible } from 'popups'

import Modal from 'components/rework/modal'

import Login from 'pages-auth/login-ui'
import LoginWithEmail from 'pages-auth/login-email'

import styles from './style.module.scss'

const mapStateToProps = ({ menu, dispatch }) => ({
  popups: menu.popups,
  step: menu.popups.step,
  dispatch,
})

class PopupDef extends React.Component {
  close = () => {
    setLoginVisible(false)
  }

  onBackClick = (e) => {
    console.log(e)
    if (e.currentTarget === e.target) {
      this.close()
    }
  }

  handleKeyDown = () => {
    // console.log(e);
    // if (e.keyCode === 8) {
    //   this.close();
    //   e.preventDefault();
    // }
  }

  render() {
    const { popups } = this.props

    if (popups.login !== true) {
      return ''
    }

    const hidden = popups.login ? '' : 'hidden'

    let loginComp = ''

    switch (popups.step) {
      case 'login':
        loginComp = <Login isPopup />
        break
      case 'loginWithEmail':
      case 'inbox':
      case 'expired':
      case 'invalidEmail':
      default:
        loginComp = <LoginWithEmail isPopup step={popups.step} />
        break
    }
    let content = (
      <div
        className={`modal-background ${hidden}`}
        role="button"
        tabIndex="-1"
        onKeyDown={this.handleKeyDown}
        onClick={(e) => {
          this.onBackClick(e)
        }}
      >
        <div className={`modal-wrap ${styles.loginPopup}`}>
          <div className="modal-wrap">
            <div className="modal-header">
              <button type="button" className="close" onClick={this.close}>
                <img src={require('assets/ic/component/ic_close.svg').default} alt="close" />
              </button>
              {/*
              <h3 className="title">Contact us</h3>
              */}
            </div>
            <div className="modal-content">{loginComp}</div>
          </div>
        </div>
      </div>
    )

    if (hidden === true) {
      content = ''
    }

    return <Modal content={content} />
  }
}

const Popup = connect(mapStateToProps)(withRouter(PopupDef))

export default Popup
