import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'router'

const mapStateToProps = ({ settings }) => ({
  isMobileView: settings.isMobileView,
})

@connect(mapStateToProps)
class DeviceSizeCheckerDef extends React.Component {
  componentDidMount() {
    const { isMobileView, onSizeChange } = this.props
    if (onSizeChange != null) {
      onSizeChange(isMobileView)
    }
  }

  componentDidUpdate(prevProps) {
    const { isMobileView, onSizeChange } = this.props
    if (prevProps.isMobileView !== isMobileView) {
      if (onSizeChange != null) {
        onSizeChange(isMobileView)
      }
    }
  }

  render() {
    const { children } = this.props

    return <React.Fragment>{children}</React.Fragment>
  }
}

const DeviceSizeChecker = withRouter(DeviceSizeCheckerDef)

export default DeviceSizeChecker
