/* eslint-disable no-underscore-dangle */

import { getDefaultArrayIFace } from 'env'

// import React from 'react'
export function extendsReactObj(reactObj, ngqlObj) {
  ngqlObj.arrayIFace = ngqlObj.gqlopt.arrayIFace

  ngqlObj.getArgument = (vars, key, defaultVal) => {
    if (vars != null) {
      return vars[key]
    }
    return defaultVal
  }

  ngqlObj.setArgument = (vars, key, val) => {
    vars[key] = val
  }

  if (ngqlObj.arrayIFace == null) {
    if (getDefaultArrayIFace != null) {
      ngqlObj.arrayIFace = getDefaultArrayIFace()
    }
    if (ngqlObj.arrayIFace != null) {
      if (ngqlObj.arrayIFace.getArgument != null) {
        ngqlObj.getArgument = ngqlObj.arrayIFace.getArgument
      }
      if (ngqlObj.arrayIFace.setArgument != null) {
        ngqlObj.setArgument = ngqlObj.arrayIFace.setArgument
      }
    }
  }

  if (reactObj != null) {
    if (ngqlObj._isInited !== true) {
      ngqlObj._isInited = true
      reactObj._isNgqlComponent = true

      ngqlObj.dataObj = {}
      ngqlObj._isStateWaiting = false
      ngqlObj._isNeedRefresh = false

      if (reactObj.state == null) {
        reactObj.state = {}
      }

      if (reactObj.state._ngqlMap == null) {
        reactObj.state._ngqlMap = {}
      }

      reactObj.isNgqlComponent = () => {
        return reactObj._isNgqlComponent
      }

      reactObj._applyNgqlObj = (nobj, value) => {
        if (reactObj.updater.isMounted(reactObj) === true) {
          const key = nobj.getName()

          const newNgqlMap = {
            ...reactObj.state._ngqlMap,
          }

          newNgqlMap[key] = {
            ...value,
          }

          reactObj.setState({
            _ngqlMap: newNgqlMap,
          })
        }
      }

      ngqlObj.loadingStateInfo = {
        name: null,
        enabled: true,
      }

      const init = (_ngqlObj) => {
        _ngqlObj.name = _ngqlObj.gqlopt.name

        if (_ngqlObj.gqlopt.pageParamName) {
          _ngqlObj.pageParamName = _ngqlObj.gqlopt.pageParamName
        }
        if (_ngqlObj.gqlopt.tableInfo) {
          _ngqlObj.tableInfo = _ngqlObj.gqlopt.tableInfo
          // initTableColumeCSS(reactObj, this.tableInfo)
        }
      }

      ngqlObj.getName = () => {
        if (ngqlObj.name == null) {
          let key = `nobj${Date.now()}`
          let idx = 0
          while (reactObj.getngqlObject(key) !== undefined) {
            key = `nobj${Date.now()}_${idx}`
            idx += 1
          }
          reactObj.state._ngqlMap[key] = null
          ngqlObj.name = key
        }
        return ngqlObj.name
      }

      ngqlObj.getLoadingName = () => {
        if (ngqlObj.loadingStateInfo.name == null) {
          if (
            ngqlObj.gqlopt != null &&
            ngqlObj.gqlopt.uiInfo != null &&
            ngqlObj.gqlopt.uiInfo.loadingStateName != null
          ) {
            ngqlObj.loadingStateInfo.name = ngqlObj.gqlopt.uiInfo.loadingStateName
          } else {
            ngqlObj.loadingStateInfo.name = `loading`
          }
        }
        return ngqlObj.loadingStateInfo.name
      }

      ngqlObj.setLoadingName = (name) => {
        ngqlObj.loadingStateInfo.name = name
      }

      ngqlObj.isLoadingStateEnabled = () => {
        let ret = ngqlObj.loadingStateInfo.enabled
        if (
          ngqlObj.gqlopt != null &&
          ngqlObj.gqlopt.uiInfo != null &&
          ngqlObj.gqlopt.uiInfo.useLoadingState != null
        ) {
          ret = ngqlObj.gqlopt.uiInfo.useLoadingState
        } else if (ngqlObj.gqlopt != null && ngqlObj.gqlopt.useLoadingUI != null) {
          ret = ngqlObj.gqlopt.useLoadingUI !== false
        }
        return ret
      }

      ngqlObj.getUIObj = () => {
        return ngqlObj.dataObj
      }

      ngqlObj.setUIObj = (value, updateUI) => {
        // reactObj.setngqlObject(key, value, updateUI)
        const newValue = {
          ...ngqlObj.dataObj,
          ...value,
        }

        ngqlObj.dataObj = newValue

        if (updateUI !== false) {
          reactObj._applyNgqlObj(ngqlObj, newValue)
        }
      }

      ngqlObj.clearUIObj = (updateUI) => {
        ngqlObj.setUIObj({}, updateUI)
      }

      ngqlObj.setLoading = (value, updateUI) => {
        const loadingName = ngqlObj.getLoadingName()
        const useLoadingState = ngqlObj.isLoadingStateEnabled()

        if (useLoadingState !== false && ngqlObj.reactObj != null) {
          const prevState = ngqlObj.getUIObj()
          if (prevState[loadingName] !== value) {
            const state = {}
            state[loadingName] = value

            ngqlObj.setUIObj(state, updateUI)
          }
        }
      }

      ngqlObj.getLoading = () => {
        const loadingName = ngqlObj.getLoadingName()
        const useLoadingState = ngqlObj.isLoadingStateEnabled()
        if (useLoadingState !== false) {
          const prevState = ngqlObj.getUIObj()
          return prevState[loadingName]
        }
        return false
      }

      init(ngqlObj)
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (ngqlObj._isInited !== true) {
      ngqlObj._isInited = true
      ngqlObj.dataObj = {}

      ngqlObj.getUIObj = () => {
        return ngqlObj.dataObj
      }

      ngqlObj.setUIObj = (value) => {
        ngqlObj.dataObj = value
      }

      ngqlObj.clearUIObj = () => {
        ngqlObj.setUIObj({})
      }

      ngqlObj.setLoading = () => {}

      ngqlObj.getLoading = () => {
        return false
      }
    }
  }
}

export default {
  extendsReactObj,
}
