import conf from '../../conf'
// import i18n from '../../i18n'

export default async function getMenuData() {
  const menuList = [
    // {
    //   category: true,
    //   title: 'Dashboards',
    // },
    {
      title: 'Features',
      // title: 'Dashboard',
      key: 'product',
      // url: '/',
      // icon: 'fe fe-home',
      children: [
        // {
        //   title: 'Features',
        //   // title: 'Workspace List',
        //   key: 'features',
        //   imgSrc: require('assets/ic/component/sliders.svg').default,
        //   url: '/features',
        //   // icon: 'fe fe-layers',
        // },
        // {
        //   divider: true,
        // },
        {
          title: 'Mail',
          // title: 'Workspace List',
          key: 'mail',
          imgSrc: require('assets/ic/component/mail.svg').default,
          url: '/mail',
          // icon: 'fe fe-layers',
        },
        {
          title: 'Calendar',
          // title: 'Workspace List',
          key: 'calendar',
          imgSrc: require('assets/ic/component/calendar.svg').default,
          url: '/calendar',
          // icon: 'fe fe-layers',
        },
        {
          title: 'Contacts',
          // title: 'Workspace List',
          key: 'contacts',
          imgSrc: require('assets/ic/component/users.svg').default,
          url: '/contacts',
          // icon: 'fe fe-layers',
        },
        {
          title: 'To Do',
          // title: 'Workspace List',
          key: 'note_todo',
          imgSrc: require('assets/ic/component/book-open.svg').default,
          url: '/notes-todo',
          // icon: 'fe fe-layers',
        },
      ],
    },
    // {
    //   category: true,
    //   title: i18n.t('user.title.list'),
    // },
    {
      title: 'Pricing',
      // title: 'Workspace List',
      key: 'pricing',
      url: '/pricing',
      // icon: 'fe fe-layers',
    },
    {
      title: 'Download',
      // title: 'User List',
      key: 'download',
      url: '/download',
      // icon: 'icmn fe fe-users',
    },
    // {
    //   title: 'Help center',
    //   // title: 'Workspace List',
    //   key: 'helpcenter',
    //   url: '/help-center',
    //   // icon: 'fe fe-smartphone',
    // },
  ]

  // if (conf.REACT_APP_MODE !== 'static') {
  //   menuList.push({
  //     title: 'Sign up',
  //     // title: 'Workspace List',
  //     key: 'sign_up',
  //     url: '/sign-up',
  //     // icon: 'fe fe-layers',
  //     authorized: false
  //   })
  // }

  // if (conf.REACT_APP_MODE === 'nine') {
  //   menuList.push({
  //     title: i18n.t('autodiscover.title.list'),
  //     // title: 'Workspace List',
  //     key: 'autodiscoverList',
  //     url: '/autodiscover/list',
  //     icon: 'fe fe-layers',
  //   })
  // }

  if (conf.DEBUG === true) {
    menuList.push({
      title: 'Settings',
      key: 'settings',
      icon: 'fe fe-settings utils__spin-delayed--pseudo-selector',
    })
  }

  return menuList
}
