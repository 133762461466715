import { combineReducers } from '@reduxjs/toolkit'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'

export default () =>
  combineReducers({
    user,
    menu,
    settings,
  })
