const defaultSettings = {
  authProvider: 'jwt', // firebase, jwt
  logo: 'Re:Work',
  locale: 'en-US',
  isSidebarOpen: false,
  isSupportChatOpen: false,
  isMobileView: false,
  isMobileMenuOpen: false,
  isMenuCollapsed: false,
  menuLayoutType: 'left', // left, top, nomenu
  routerAnimation: 'none', // none, slide-fadein-up, slide-fadein-right, fadein, zoom-fadein
  menuColor: 'white', // white, dark, gray
  theme: 'default', // default, dark
  authPagesColor: 'white', // white, gray, image
  primaryColor: '#4b7cf3',
  leftMenuWidth: 200, // 256
  isMenuUnfixed: false,
  isMenuShadow: false,
  isTopbarFixed: false,
  isGrayTopbar: true,
  isContentMaxWidth: true,
  isAppMaxWidth: true,
  isGrayBackground: true,
  isCardShadow: true,
  isSquaredBorders: false,
  isBorderless: false,
  isHeaderBlack: false,
}

export default defaultSettings
