/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react'
import { withRouter } from 'router'
import { connect } from 'react-redux'

import { setMainVisible } from 'popups'

import Modal from '../../components/rework/modal'

import styles from './style.module.scss'

const mapStateToProps = ({ menu, dispatch }) => ({
  popups: menu.popups,
  dispatch,
})

class PopupDef extends React.Component {
  close = () => {
    setMainVisible(false)
  }

  onBackClick = (e) => {
    console.log(e)
    if (e.currentTarget === e.target) {
      this.close()
    }
  }

  handleKeyDown = () => {
    // console.log(e);
    // if (e.keyCode === 8) {
    //   this.close();
    //   e.preventDefault();
    // }
  }

  render() {
    const { popups } = this.props

    if (popups.main !== true) {
      return ''
    }

    let curComp = ''
    if (popups.targetURL != null) {
      curComp = popups.targetURL
    }

    const hidden = popups.main ? '' : 'hidden'
    let content = (
      <div
        className={`modal-background ${hidden}`}
        role="button"
        tabIndex="-1"
        onKeyDown={this.handleKeyDown}
        onClick={(e) => {
          this.onBackClick(e)
        }}
      >
        <div className={`modal-wrap ${styles.mainPopup}`}>
          <iframe className={styles.frame} src={curComp} title="rework" />
          <button type="button" className={styles.close} onClick={this.close}>
            <img src={require('assets/ic/component/ic_close.svg').default} alt="close" />
          </button>
        </div>
      </div>
    )

    if (hidden === true) {
      content = ''
    }

    return <Modal content={content} />
  }
}

const Popup = connect(mapStateToProps)(withRouter(PopupDef))

export default Popup
