import React from 'react'
import { withRouter } from 'router'

import MyHeader from '../index'
import Menu from '../../Menu/public'

class HeaderDef extends React.Component {
  render() {
    const { black } = this.props

    return <MyHeader menuComp={Menu} black={black} />
  }
}

const Header = withRouter(HeaderDef)

export default Header
