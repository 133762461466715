import conf from '../../conf'
import i18n from '../../i18n'

export default async function getAuthMenuData() {
  const accountMenuList = [
    // {
    //   category: true,
    //   title: 'Dashboards',
    // },
    {
      // title: 'Account',
      title: i18n.t('menu.account'),
      key: 'Profile',
      url: '/account/profile',
      icon: 'icmn fe fe-user',
    },
    {
      // title: 'Workspace',
      title: i18n.t('menu.workspace'),
      key: 'workspace',
      url: '/account/myWorkspace',
      icon: 'fe fe-briefcase',
    },
    {
      // title: 'Device',
      title: i18n.t('menu.device'),
      key: 'deviceList',
      url: '/account/device/list',
      icon: 'fe fe-smartphone',
    },
    {
      // title: 'Billing',
      title: i18n.t('menu.billing'),
      key: 'Payment',
      url: '/account/payment',
      icon: 'fe fe-credit-card',
      // icon: 'fe fe-sidebar',
    },
  ]

  // if (conf.REACT_APP_MODE !== 'static') {
  //   menuList.push({
  //     title: 'Sign up',
  //     // title: 'Workspace List',
  //     key: 'sign_up',
  //     url: '/sign-up',
  //     // icon: 'fe fe-layers',
  //     authorized: false
  //   })
  // }

  // if (conf.REACT_APP_MODE === 'nine') {
  //   menuList.push({
  //     title: i18n.t('autodiscover.title.list'),
  //     // title: 'Workspace List',
  //     key: 'autodiscoverList',
  //     url: '/autodiscover/list',
  //     icon: 'fe fe-layers',
  //   })
  // }

  if (conf.DEBUG === true) {
    accountMenuList.push({
      title: 'Settings',
      key: 'settings',
      icon: 'fe fe-settings utils__spin-delayed--pseudo-selector',
    })
  }

  return accountMenuList
}
