import React from 'react'
import ReactDOM from 'react-dom'

class Modal extends React.Component {
  modalRoot = null;

  constructor() {
    super()
    this.el = document.createElement('div')
  }

  componentDidMount() {
    this.modalRoot = document.getElementById('rework-modal-container');
    this.modalRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.el)
  }

  handleChange = value => {
    // console.log(' --- sel changed ', value);
    this.selectValue = value
  }

  search = value => {
    const { searchFunc } = this.props
    // console.log(' --- search ', this.selectValue, value);
    searchFunc(this.selectValue, value)
  }

  render() {
    const { content } = this.props

    return ReactDOM.createPortal(content, this.el)
  }
}

export default Modal
