import NgqlUI from 'gql/ninegqlUI'

const envParam = {}

export function setEnvParam(key, value) {
  envParam[key] = value
}

export function getEnvParam(key) {
  return envParam[key]
}

const config = {
  defaultPage: '/account/profile',
};

export function getConfig(key) {
  if (key == null) {
    return config
  }

  return config[key]
}

const ngqlUIInfo = NgqlUI

// export function setNgqlUIInfo(prop) {
//   ngqlUIInfo = prop;
// }
// setNgqlUIInfo(NgqlUI);

export function getDefaultArrayIFace() {
  const iface = {
    getNodes: (resData) => {
      let ret = null
      if (resData != null && resData.edges != null) {
        ret = resData.edges
      } else if (Array.isArray(resData) === true) {
        ret = resData.map((item) => {
          return {
            node: item,
          }
        })
      } else {
        ret = [{ node: resData }]
      }
      return ret
    },
    getMoreCursor: (resData, lastItemData) => {
      if (resData != null && resData.pageInfo != null) {
        return resData.pageInfo.endCursor
      }
      if (lastItemData != null) {
        return lastItemData.cursor
      }
      return null
    },
    getTotalCount: (resData) => {
      let ret = 1
      if (resData != null && resData.totalCount != null) {
        ret = resData.totalCount
      } else if (Array.isArray(resData) === true) {
        ret = resData.length
      }
      return ret
    },
    getArgument: (vars, key, defaultVal) => {
      if (vars != null) {
        return vars[key]
      }
      return vars[key] != null ? vars[key] : defaultVal
    },
    setArgument: (vars, key, val) => {
      vars[key] = val
    },
  }

  return iface
}

// export function getDefaultArrayIFace() {
//   return null;
// }

export function getNgqlUIInfo() {
  return ngqlUIInfo
}

export function getNgqlConfig() {
  return {
    tableClickRange: 8,
  }
}

export const LoginPath = '/loginPopup'
