import React, { useState, useEffect } from 'react'
// import { Link } from 'react-router-dom'
import { withRouter } from 'router'
import { connect } from 'react-redux'
import { reduce } from 'lodash'
// import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'

const mapStateToProps = ({ menu, settings }) => ({
  menuData: menu.authMenuData,
  locale: settings.locale,
})

const Breadcrumbs = props => {
  // const { t } = useTranslation()
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const {
    location: { pathname },
    menuData = [],
  } = props
  useEffect(() => {
    setBreadcrumbs(() => getBreadcrumbs())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menuData])

  const getPath = (data, url, parents = []) => {
    const items = reduce(
      data,
      (result, entry) => {
        if (result.length) {
          return result
        }
        if (entry.url === url) {
          return [entry].concat(parents)
        }
        if (url.indexOf(entry.url) === 0) {
          return [entry].concat(parents)
        }
        if (entry.children) {
          const nested = getPath(entry.children, url, [entry].concat(parents))
          return (result || []).concat(nested.filter(e => !!e))
        }
        return result
      },
      [],
    )

    if (items.length === 0) {
      let curMenu = null
      let mCnt = 0
      const pathArr = url.split('/')
      data.forEach(menu => {
        if (menu.url != null) {
          const urlArr = menu.url.split('/')
          const len = Math.min(urlArr.length, pathArr.length)
          for (let i = 0; i < len; i += 1) {
            if (pathArr[i] === urlArr[i]) {
              if (mCnt < i + 1) {
                mCnt = i + 1
                curMenu = menu
              }
            } else {
              break
            }
          }
        }
      })

      if (curMenu != null) {
        items.push(curMenu)
      }
    }
    return items.length > 0 ? items : [false]
  }

  const getBreadcrumbs = () => {
    const [activeMenuItem, ...path] = getPath(menuData, pathname)

    if (!activeMenuItem) {
      return null
    }
    const activeMenuItemTitle = activeMenuItem.title
    if (activeMenuItem && path.length) {
      return path.reverse().map((item, index) => {
        if (index === path.length - 1) {
          return (
            <span key={item.key}>
              <span className={styles.arrow} />
              <span>{item.title}</span>
              <span className={styles.arrow} />
              <strong className={styles.current}>{activeMenuItemTitle}</strong>
            </span>
          )
        }
        const itemTitle = item.title
        return (
          <span key={item.key}>
            <span className={styles.arrow} />
            <span>{itemTitle}</span>
          </span>
        )
      })
    }
    return (
      <span>
        <span className={styles.arrow} />
        <strong className={styles.current}>{activeMenuItemTitle}</strong>
      </span>
    )
  }

  return (
    breadcrumbs && (
      <div className={styles.breadcrumbs}>
        <div className={styles.path}>
          {/* <Link to="/dashboard/nine">{t('menu.home')}</Link> */}
          {breadcrumbs}
        </div>
      </div>
    )
  )
}

export default withRouter(connect(mapStateToProps)(Breadcrumbs))
