/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unescaped-entities */

import React from 'react'
import { Button, notification } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'router'
import { withTranslation } from 'react-i18next'
import { getSearchParams } from 'utils'
import { restClient } from 'myNet'

import { setLoginVisible } from 'popups'

// import Accordion from '../../../components/rework/accordion'

import styles from './style.module.scss'

const mapStateToProps = ({ user, dispatch, menu }) => ({
  popups: menu.popups,
  info: menu.popups.info,
  dispatch,
  user,
})

@connect(mapStateToProps)
class LoginUIDef extends React.Component {
  state = {
    errorMsg: '',
    btnLoading: false,
  }

  inputRef = React.createRef()

  btnRef = React.createRef()

  okBtnRef = React.createRef()

  componentDidMount() {
    const { isPopup } = this.props
    if (isPopup !== true) {
      this.setHeaderBlack(false)
    }

    setTimeout(() => {
      if (this.okBtnRef.current != null) {
        this.okBtnRef.current.focus()
      }
    }, 1)
  }

  componentDidUpdate(prevProps) {
    const { popups } = this.props

    if (prevProps.popups.step !== popups.step) {
      setTimeout(() => {
        if (this.okBtnRef.current != null) {
          this.okBtnRef.current.focus()
        }
      }, 1)
    }
  }
  // componentWillUnmount() {
  //   this.setHeaderBlack(false);
  // }

  setHeaderBlack = (value) => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/SET_STATE',
      payload: {
        isHeaderBlack: value,
      },
    })
  }

  moveBack = () => {
    const { history } = this.props
    if (history.length === 0) {
      // history.push('/account/users/list')
    } else {
      history.goBack()
    }
  }

  onOK = () => {
    const { isPopup, history } = this.props
    if (isPopup !== true) {
      history.push('/')
    } else {
      setLoginVisible(false)
    }
  }

  onSubmit = (evt) => {
    const { info } = this.props
    const { btnLoading } = this.state

    if (btnLoading === true) {
      evt.preventDefault()
      return
    }

    // const { history } = this.props
    // const { dispatch } = this.props

    console.log('onSubmit - ', evt)
    // const { email, password } = evt.target.elements
    const { email } = evt.target.elements
    const values = {
      email: email.value.trim(),
      // password: password.value.trim(),
    }

    console.log(values)

    const sendEmailURL = '/auth/send/link'

    this.setState({
      btnLoading: true,
    })

    const sendEmailParam = {
      email: values.email,
    }

    if (info != null && info.target != null) {
      sendEmailParam.after_redirect_path = info.target
    }

    restClient
      .post(sendEmailURL, sendEmailParam)
      .then((res) => {
        console.log(res)

        // history.push(`/login-email?step=inbox&email=${values.email}`)
        setLoginVisible(null, 'inbox', {
          email: values.email,
        })

        setTimeout(() => {
          this.setState({
            btnLoading: false,
          })
        }, 500)
      })
      .catch((error) => {
        if (error.code === 401 || error.code === 604) {
          if (this.btnRef.current != null && this.inputRef.current != null) {
            this.inputRef.current.value = ''
            this.btnRef.current.disabled = true
            this.setState({
              errorMsg: '',
            })
          }
          setLoginVisible(
            null,
            'invalidEmail',
            {
              prevEmail: values.email,
            },
            info,
          )
        } else {
          notification.warning({
            message: error.code,
            description: error.message,
          })
        }

        setTimeout(() => {
          this.setState({
            btnLoading: false,
          })
        }, 500)
      })

    evt.preventDefault()
  }

  // onFinishFailed = errorInfo => {
  //   console.log('Failed:', errorInfo)
  // }

  onInputChange = () => {
    if (this.btnRef.current != null && this.inputRef.current != null) {
      this.btnRef.current.disabled =
        this.inputRef.current.value.length === 0 || this.inputRef.current.validity.valid === false
      this.setState({
        errorMsg: this.inputRef.current.validationMessage,
      })
    }
  }

  render() {
    const { errorMsg, btnLoading } = this.state
    const { popups } = this.props
    const params = getSearchParams()

    const realStep = popups.step || params.step
    const realEmail = popups.info && popups.info.email ? popups.info.email : params.email

    const prevEmail = popups.info && popups.info.prevEmail ? `${popups.info.prevEmail}` : ''

    const errMsg = errorMsg.length > 0 ? <span className={styles.errorMsg}>{errorMsg}</span> : ''

    if (realStep === 'inbox') {
      return (
        <div className="section center">
          <div className="login-form-group">
            <h1>Please check your Inbox</h1>
            <br />
            <p>Click the link we sent to</p>
            <p>{realEmail} to sign in</p>
            <br />
            <br />
            <br />
            <br />
            <Button
              ref={this.okBtnRef}
              type="primary"
              shape="round"
              size="large"
              onClick={this.onOK}
            >
              &nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp;
            </Button>
          </div>
        </div>
      )
    }

    let title = (
      <React.Fragment>
        <h1>Sign in with email</h1>
        <br />
        <p>
          Please enter the email address associated with your account, we’ll send a magic link to
          your inbox.
        </p>
        <br />
      </React.Fragment>
    )

    if (realStep === 'expired') {
      title = (
        <React.Fragment>
          <h1>Your sign-in link has expired.</h1>
          <br />
          <p>
            Enter the email address associated with your account, and we'll send a new magic link to
            your inbox.
          </p>
          <br />
        </React.Fragment>
      )
    }
    if (realStep === 'invalidEmail') {
      title = (
        <React.Fragment>
          <h1>Sorry, we didn’t recognize the email</h1>
          <h2 className={styles.prevEmail}>{prevEmail}</h2>
          <br />
          <p>Would you like to sign in with a different email?</p>
          <br />
        </React.Fragment>
      )
    }
    return (
      <div className={`section center ${styles.formContainer}`}>
        <div className="login-form-group">
          <form onSubmit={this.onSubmit}>
            {title}
            <div className="form-group">
              <input
                ref={this.inputRef}
                name="email"
                type="email"
                className={`form-control ${styles.email}`}
                placeholder="Email"
                onChange={this.onInputChange}
              />
              {errMsg}
            </div>
            {/*
            <div className="form-group">
              <input
                name="password"
                type="password"
                className="form-control"
                placeholder="Password"
              />
            </div>
            */}
            <Button
              ref={this.btnRef}
              type="primary"
              htmlType="submit"
              shape="round"
              size="large"
              loading={btnLoading}
              className="btn btn-primary"
              disabled
            >
              &nbsp;Continue&nbsp;
            </Button>
            {/*
            <Button type="primary" to="/login-email" onClick={() => {setLoginVisible(null, 'inbox', {email: 'dfd.@dfd.dfd'})}}>
              test
            </Button>
            */}
          </form>
        </div>
      </div>
    )
  }
}

const LoginUI = withTranslation()(withRouter(LoginUIDef))

export default LoginUI
