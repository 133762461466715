import { store as reduxStore } from 'index'

export function setMainVisible(value, url) {
  reduxStore.dispatch({
    type: 'menu/SET_POPUP',
    payload: {
      main: value,
      targetURL: url,
    },
  })

  if (value === true) {
    document.body.setAttribute('scroll', 'false')
  } else {
    document.body.setAttribute('scroll', 'true')
  }
}

export function setLoginVisible(value, step, info) {
  if (value !== null) {
    reduxStore.dispatch({
      type: 'menu/SET_POPUP',
      payload: {
        login: value,
        step,
        // step: step != null ? step : 'login',
        info,
      },
    })

    if (value === true) {
      document.body.setAttribute('scroll', 'false')
    } else {
      document.body.setAttribute('scroll', 'true')
    }
  } else {
    reduxStore.dispatch({
      type: 'menu/SET_POPUP',
      payload: {
        step,
        // step: step != null ? step : 'login',
        info,
      },
    })
  }
}

export function setContactVisible(value) {
  if (window.parent === window) {
    reduxStore.dispatch({
      type: 'menu/SET_POPUP',
      payload: {
        contacts: value,
      },
    })

    if (value === true) {
      document.body.setAttribute('scroll', 'false')
    } else {
      document.body.setAttribute('scroll', 'true')
    }
  } else {
    window.parent.nPopup.setContactVisible(true, 'loginWithEmail')
  }
}

const popupProps = {
  setMainVisible,
  setLoginVisible,
  setContactVisible,
}

export default popupProps
