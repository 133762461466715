const History = {
  navigate: null,
  location: window.location,
  push: (page, ...rest) => History.navigate(page, ...rest),
  replace: (page) => History.navigate(page, { replace: true }),
  goback: () => History.navigate(-1),
  back: (step) => {
    const num = (step != null) ? step : -1;
    History.navigate(num);
  },
  locationChangeCBList: [],
  listen: (handleLocationChange) => {
    if (History.locationChangeCBList.indexOf(handleLocationChange) < 0) {
      History.locationChangeCBList.push(handleLocationChange);
    }
    const ret = ((cbFunc) => {
      return () => {
        History.unlisten(cbFunc);
      }
    })(handleLocationChange);
    return ret;
  },
  unlisten: (handleLocationChange) => {
    const pos = History.locationChangeCBList.indexOf(handleLocationChange);
    if (History.locationChangeCBList.indexOf(handleLocationChange) > -1) {
      History.locationChangeCBList.splice(pos, 1);
    }
  },
  notifyLocationChange: (location) => {
    History.locationChangeCBList.forEach((cbFunc) => {
      cbFunc(location);
    })
  }
};

export default History;
