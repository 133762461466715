import React from 'react'
import { Tabs } from 'antd'

import JsonEditor from '../JsonEditor'
import SchemaJsonEditor from '../SchemaJsonEditor'

const { TabPane } = Tabs

class JsonEditorChooser extends React.PureComponent {
  editorType = 'normal' // 'schema'

  curTabKey = '1'

  constructor(props) {
    super(props)
    this.state = {
      dirty: false,
    }
  }

  componentDidMount() {
    const { value } = this.props

    this.setValue(value)
  }

  componentDidUpdate(prevProps /* , prevState */) {
    const { onDirty, value } = this.props
    const { dirty, controllingFocus } = this.state

    if (dirty === true && controllingFocus) {
      onDirty()
    }

    if (dirty === true && !controllingFocus) {
      this.timeout = setTimeout(() => {
        this.setState({
          dirty: false,
        })
      }, 100)
    }

    if (controllingFocus) {
      clearTimeout(this.timeout)
    }

    if (prevProps.value !== value) {
      this.setValue(value)
      setTimeout(() => {
        this.setState({
          dirty: false,
        })
      }, 1)
    }
  }

  componentWillUnmount() {
    // this.editor.destroy()
    // delete this.editor
    clearTimeout(this.timeout)
  }

  getEditor = () => {
    switch (this.curTabKey) {
      case '2':
        return this.editorWithSchema
      case '1':
      default:
        return this.editor
    }
  }

  setValue = value => {
    console.log(value)

    this.getEditor().setValue(value)

    if (this.div != null) {
      this.div.value = value
      this.div.setAttribute('value', value)
    }
  }

  getValue = () => {
    return this.getEditor().getValue()
  }

  handleChange = () => {
    const { onChange } = this.props

    const value = this.getValue()

    if (onChange != null) {
      onChange(value)
    }

    if (this.div != null) {
      this.div.value = value
      this.div.setAttribute('value', value)
    }
  }

  handleFocus = () => {
    this.setState({
      controllingFocus: true,
    })
  }

  handleBlur = () => {
    this.setState({
      controllingFocus: false,
    })
  }

  handleError = e => {
    const { onError } = this.props

    console.log('handleError : ', e)

    if (onError != null) {
      onError({
        hasError: e.length > 0,
        errors: e,
      })
    }
  }

  onTabChange = key => {
    console.log('onTabChange : ', key)
    if (this.curTabKey === key) {
      return
    }

    this.curTabKey = key
    switch (this.curTabKey) {
      case '2':
        {
          const v = this.editor.getValue()
          if (this.editorWithSchema != null) {
            this.editorWithSchema.setValue(v)
          }
        }
        break
      case '1':
      default: {
        if (this.editorWithSchema != null) {
          const v = this.editorWithSchema.getValue()
          this.editor.setValue(v)
        }
      }
    }
  }

  render() {
    const { className, style, onError, options, inputAttrs, value } = this.props

    const inputAttrs2 = {
      ...inputAttrs,
    }

    delete inputAttrs2.options

    return (
      <div
        className={className}
        ref={div => {
          this.div = div
        }}
      >
        <Tabs style={style} defaultActiveKey="1" onChange={this.onTabChange}>
          <TabPane tab="JsonEditor" key="1">
            <JsonEditor
              ref={ref => {
                this.editor = ref
              }}
              onError={onError}
              onChange={this.handleChange}
              options={options}
              value={value}
              {...inputAttrs2}
            />
          </TabPane>
          <TabPane tab="SchemaJsonEditor" key="2">
            <SchemaJsonEditor
              ref={ref => {
                this.editorWithSchema = ref
              }}
              onError={onError}
              onChange={this.handleChange}
              value={value}
              {...inputAttrs}
            />
          </TabPane>
        </Tabs>
      </div>
    )
  }
}

export default JsonEditorChooser
