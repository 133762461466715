import React from 'react'
// import React, { lazy, Suspense } from 'react'
import loadable from '@loadable/component'
import { Spin } from 'antd'
import { Route, Navigate, Routes, useLocation, useParams, useNavigate } from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import { history as globalHistory } from 'index'
// import store from 'store'

import { setLoginVisible, setMainVisible } from 'popups'
import MainModal from 'popups/main'
import LoginModal from 'popups/login'
import ContactModal from 'popups/contact-us'

import { getSearchParams, parseSearchParam, processNParam } from 'utils'

import Layout from 'layouts'

export function withRouter(Component, options) {
  return (props) => {
    const newProps = {
      ...props
    };

    newProps.location = useLocation();
    newProps.navigate = useNavigate();
    newProps.history = globalHistory;
    newProps.match = {
      params: useParams(),
      path: newProps.location.pathname
    };

    if (options != null && options.navigate === true) {
      newProps.navigate = useNavigate();
    }

    return (
      <Component {...newProps} />
    )
  };
}

const config = {
  fallback: <Spin />,
}

let routes = [
  {
    path: '/home',
    Component: loadable(() => import('pages-static/home'), config),
    exact: true,
  },
  // {
  //   path: '/features',
  //   Component: loadable(() => import('pages-static/features'), config),
  //   exact: true,
  // },
  {
    path: '/mail',
    Component: loadable(() => import('pages-static/mail'), config),
    exact: true,
  },
  {
    path: '/calendar',
    Component: loadable(() => import('pages-static/calendar'), config),
    exact: true,
  },
  {
    path: '/contacts',
    Component: loadable(() => import('pages-static/contacts'), config),
    exact: true,
  },
  {
    path: '/notes-todo',
    Component: loadable(() => import('pages-static/notes-todo'), config),
    exact: true,
  },
  {
    path: '/pricing',
    Component: loadable(() => import('pages-static/pricing'), config),
    exact: true,
  },
  {
    path: '/download',
    Component: loadable(() => import('pages-static/download'), config),
    exact: true,
  },
  {
    path: '/plain/waiting',
    Component: loadable(() => import('pages-static/waiting'), config),
    exact: true,
  },
  // {
  //   path: '/help-center',
  //   Component: loadable(() => import('pages-static/help-center'), config),
  //   exact: true,
  // },
  {
    path: '/privacy-policy',
    Component: loadable(() => import('pages-static/privacy-policy'), config),
    exact: true,
  },
  {
    path: '/terms-and-conditions',
    Component: loadable(() => import('pages-static/terms-and-conditions'), config),
    exact: true,
  },
  {
    path: '/plain/privacy-policy',
    Component: loadable(() => import('pages-static/privacy-policy'), config),
    exact: true,
  },
  {
    path: '/plain/terms-and-conditions',
    Component: loadable(() => import('pages-static/terms-and-conditions'), config),
    exact: true,
  },
  // {
  //   path: '/public/test',
  //   Component: loadable(() => import('pages/test'), config),
  //   exact: true,
  // },
  {
    path: '/profile',
    Component: loadable(() => import('pages/profile'), config),
    exact: true,
  },
  {
    path: '/redirect',
    Component: loadable(() => import('pages-auth/redirect'), config),
    exact: true,
  },
  {
    path: '/auth/login/:key',
    Component: loadable(() => import('pages-auth/redirectByKey'), config),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: loadable(() => import('pages-auth/404'), config),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: loadable(() => import('pages-auth/500')),
    exact: true,
  },
]

if (process.env.REACT_APP_MODE !== 'static') {
  const authRoutes = [
    // {
    //   path: '/login-ui',
    //   Component: loadable(() => import('pages-auth/login-ui'), config),
    //   exact: true,
    // },
    {
      path: '/login-email2',
      Component: loadable(() => import('pages-auth/login-email'), config),
      exact: true,
    },
    // {
    //   path: '/sign-up',
    //   Component: loadable(() => import('pages-auth/sign-up'), config),
    //   exact: true,
    // },
    {
      path: '/account/profile',
      // Component: loadable(() => import('pages/profile')),
      Component: loadable(() => import('pages/accountinfo/myDetail'), config),
      exact: true,
    },
    {
      path: '/account/payment',
      Component: loadable(() => import('pages/payment'), config),
      exact: true,
    },
    // {
    //   path: '/account/workspace/list',
    //   Component: loadable(() => import('pages/workspace/list'), config),
    //   exact: true,
    // },
    {
      path: '/account/myWorkspace',
      Component: loadable(() => import('pages/workspace/myDetail'), config),
      exact: true,
    },
    // {
    //   path: '/account/workspace/detail/:id',
    //   Component: loadable(() => import('pages/workspace/detail'), config),
    // },
    // {
    //   path: '/account/workspace/edit/:id',
    //   Component: loadable(() => import('pages/workspace/edit'), config),
    // },
    // {
    //   path: '/account/workspace/edit',
    //   Component: loadable(() => import('pages/workspace/edit'), config),
    // },
    // {
    //   path: '/account/workspace/create',
    //   Component: loadable(() => import('pages/workspace/edit'), config),
    // },
    // {
    //   path: '/account/users/list',
    //   Component: loadable(() => import('pages/users/list'), config),
    // },
    // {
    //   path: '/account/users/edit/:id',
    //   Component: loadable(() => import('pages/users/edit'), config),
    // },
    // {
    //   path: '/account/users/detail/:id',
    //   Component: loadable(() => import('pages/users/detail'), config),
    // },
    // {
    //   path: '/account/users/create',
    //   Component: loadable(() => import('pages/users/edit'), config),
    // },
    {
      path: '/account/device/list',
      Component: loadable(() => import('pages/device/list'), config),
    },
    // {
    //   path: '/account/device/detail/:id',
    //   Component: loadable(() => import('pages/device/detail'), config),
    // },
    // {
    //   path: '/account/device/edit/:id',
    //   Component: loadable(() => import('pages/device/edit'), config),
    // },
    // {
    //   path: '/account/device/create',
    //   Component: loadable(() => import('pages/device/edit'), config),
    // },
    // {
    //   path: '/account/accountinfo/list',
    //   Component: loadable(() => import('pages/accountinfo/list'), config),
    // },
    // {
    //   path: '/account/accountinfo/detail/:id',
    //   Component: loadable(() => import('pages/accountinfo/detail'), config),
    // },
    // {
    //   path: '/account/accountinfo/edit/:id',
    //   Component: loadable(() => import('pages/accountinfo/edit'), config),
    // },
    // {
    //   path: '/account/accountinfo/create',
    //   Component: loadable(() => import('pages/accountinfo/edit'), config),
    // },
    // {
    //   path: '/account/signature/list',
    //   Component: loadable(() => import('pages/signature/list'), config),
    // },
    // {
    //   path: '/account/signature/detail/:id',
    //   Component: loadable(() => import('pages/signature/detail'), config),
    // },
    // {
    //   path: '/account/signature/edit/:id',
    //   Component: loadable(() => import('pages/signature/edit'), config),
    // },
    // {
    //   path: '/account/signature/create/:id',
    //   Component: loadable(() => import('pages/signature/edit'), config),
    // },
    // {
    //   path: '/account/push/list',
    //   Component: loadable(() => import('pages/push/list'), config),
    // },
    // {
    //   path: '/account/push/detail/:id',
    //   Component: loadable(() => import('pages/push/detail'), config),
    // },
    // {
    //   path: '/account/push/edit/:id',
    //   Component: loadable(() => import('pages/push/edit'), config),
    // },
    // {
    //   path: '/account/push/create/:id',
    //   Component: loadable(() => import('pages/push/edit'), config),
    // },
    // {
    //   path: '/auth/login',
    //   Component: loadable(() => import('pages/auth/login'), config),
    //   exact: true,
    // },
    // {
    //   path: '/auth/lockscreen',
    //   Component: loadable(() => import('pages/auth/lockscreen'), config),
    //   exact: true,
    // },
  ]

  routes = routes.concat(authRoutes)
}

const mapStateToProps = ({ settings, user }) => ({
  routerAnimation: settings.routerAnimation,
  user,
})

@connect(mapStateToProps)
class Router extends React.Component {
  constructor(props) {
    super(props)
    // store.set('app.router.location', '');

    const { history } = props
    this.unsubscribeFromHistory = history.listen(this.handleLocationChange)
    this.handleLocationChange(history.location)
  }

  // componentDidMount() {
  // }

  componentWillUnmount() {
    if (this.unsubscribeFromHistory) this.unsubscribeFromHistory()
  }

  handleLocationChange = e => {
    const { history, user } = this.props

    console.log('ROUTE CHANGED', e, history)
    if (e.pathname === '/loginPopup' || e.pathname === '/login-email') {
      const params = getSearchParams()

      const { step, email, target } = params
      console.log(step, email, target)
      const prevPath = '/home'

      const isLoginState = user.role != null && user.role.length > 0

      if (isLoginState !== true) {
        // const locStr = store.get('app.router.location');
        // if (locStr.length > 0) {
        //   prevPath = JSON.parse(locStr);
        // }
        if (window.parent === window) {
          setTimeout(() => {
            history.replace(prevPath)
          }, 100)
          setLoginVisible(true, step, { email, target })
        } else {
          window.parent.nPopup.setLoginVisible(true, step, { email, target })
        }
      } else {
        let targetPath = null
        if (target === 'payment') {
          targetPath = '/account/payment'
        } else if (target === 'device') {
          targetPath = '/account/device/list'
        } else {
          targetPath = '/account/profile'
        }
        if (target != null) {
          if (window.parent === window) {
            setTimeout(() => {
              history.replace(prevPath)
            }, 100)
            setMainVisible(true, targetPath)
          } else {
            window.parent.nPopup.setMainVisible(true, targetPath)
          }
        } else {
          history.replace(prevPath)
        }
      }
    } else {
      parseSearchParam()
      processNParam()
    }
  }

  // const Router = ({ history, routerAnimation }) => {
  render = () => {
    const { routerAnimation, location } = this.props
    return (
      <React.Fragment>
        <Layout>
          <SwitchTransition>
            <CSSTransition
              key={location.pathname}
              appear
              classNames={routerAnimation}
              timeout={routerAnimation === 'none' ? 0 : 300}
            >
              <Routes location={location}>
                <Route exact path="/" element={(<Navigate to="/home" />)} />
                {routes.map(({ path, Component, exact }) => (
                  <Route
                    path={path}
                    key={path}
                    exact={exact}
                    element={(
                      <div className={routerAnimation}>
                        <Component />
                      </div>
                    )}
                  />
                ))}
                <Route exact path="*" element={<Navigate to="/auth/404" />} />
              </Routes>
            </CSSTransition>
          </SwitchTransition>
        </Layout>
        <div className="modal-container" id="rework-modal-container" />
        <MainModal />
        <LoginModal />
        <ContactModal />
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps)(withRouter(Router))
