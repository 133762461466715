import { all, put, takeEvery, call } from 'redux-saga/effects'
import i18n from 'i18n'

import getMenuData from 'services/menu'
import getAuthMenuData from 'services/menu-auth'
import actions from './actions'

export function* GET_DATA() {
  if (i18n.isInitialized !== true) {
    return
  }
  const menuData = yield call(getMenuData)
  const authMenuData = yield call(getAuthMenuData)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
      authMenuData,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.RELOAD, GET_DATA),
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
