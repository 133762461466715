/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react'
import { notification } from 'antd'
import { withRouter } from 'router'
import { connect } from 'react-redux'

import queryString from 'query-string'

import conf from 'conf'

import Modal from '../../components/rework/modal'

const mapStateToProps = ({ menu, dispatch }) => ({
  popups: menu.popups,
  dispatch,
})

class PopupDef extends React.Component {
  formRef = React.createRef()

  close = () => {
    const { dispatch } = this.props

    dispatch({
      type: 'menu/SET_POPUP',
      payload: {
        contacts: false,
      },
    })
  }

  onBackClick = (e) => {
    console.log(e)
    if (e.currentTarget === e.target) {
      this.close()
    }
  }

  movePrivacyPolicy = (e) => {
    // const { history } = this.props
    // this.close()

    // history.push('/privacy-policy')
    window.open('/privacy-policy', '', '_blank')

    e.preventDefault()
  }

  handleKeyDown = () => {}

  onSubmit = (e, param1) => {
    const self = this
    const { t } = this.props

    console.log('onSubmit - ', e, param1)
    const form = this.formRef.current
    const fields = {}

    form.forEach((item) => {
      if (item.name.length > 0) {
        fields[item.name] = item.value
      }
    })

    console.log('values - ', fields)

    const contactApi = `${conf.SERVER.restBase}/sales/contact`

    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }

    const body = queryString.stringify(fields)

    fetch(contactApi, {
      method: 'POST',
      headers,
      body,
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data)
          if (data.error != null) {
            notification.warning({
              message: t('contact.title.error'),
              description: data.error,
            })
          } else {
            notification.info({
              message: t('contact.title.success'),
              description: t('contact.message.success'),
            })
            self.close()
          }
        })
      })
      .catch((error) => {
        notification.warning({
          message: error.code,
          description: error.message,
        })
      })

    e.preventDefault()
  }

  render() {
    const { popups } = this.props

    if (popups.contacts !== true) {
      return ''
    }

    let content = ''
    if (popups.contacts === true) {
      content = (
        <div
          className="modal-background"
          role="button"
          tabIndex="-1"
          onKeyDown={this.handleKeyDown}
          onClick={(e) => {
            this.onBackClick(e)
          }}
        >
          <div className="modal-wrap">
            <div className="modal-header">
              <button type="button" className="close" onClick={this.close}>
                <img src={require('assets/ic/component/ic_close.svg').default} alt="close" />
              </button>
              <h3 className="title">Contact us</h3>
            </div>
            <div className="modal-content">
              <form ref={this.formRef} onSubmit={this.onSubmit}>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    required
                    name="email"
                    placeholder="Email"
                  />
                  <input
                    type="text"
                    className="form-control"
                    required
                    name="name"
                    placeholder="Name"
                  />
                  <input
                    type="text"
                    className="form-control"
                    required
                    name="company_name"
                    placeholder="Company name"
                  />
                  <input
                    type="number"
                    className="form-control"
                    required
                    name="number_of_employees"
                    placeholder="Number of employees"
                  />
                  <label htmlFor="question1" className="form-label">
                    Inquiry (optional)
                  </label>
                  <textarea
                    className="form-control"
                    name="inquiry"
                    cols={30}
                    rows={10}
                    placeholder="Please tell us about your question"
                    defaultValue=""
                  />
                  <button type="submit" className="btn btn-primary btn-block">
                    Send
                  </button>
                  <div className="info">
                    By submitting the form, I agree to the{' '}
                    <a
                      className="link"
                      role="button"
                      tabIndex="-1"
                      onKeyDown={this.handleKeyDown}
                      onClick={this.movePrivacyPolicy}
                    >
                      Privacy Policy
                    </a>
                    .
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )
    }

    return <Modal content={content} />
  }
}

const Popup = connect(mapStateToProps)(withRouter(PopupDef))

export default Popup
